<template>
  <v-layout wrap class="place-info-form">
    <v-flex xs12 v-if="label">
      <p class="label">{{label}} :</p>
    </v-flex>
    <v-flex xs12 style="padding: 5px 0;">
      <v-autocomplete
        v-model="placeID"
        :items="places"
        outlined
        itemText="Name"
        itemValue="ID"
        hide-details
        @change="handleChange"
      />
    </v-flex>
  </v-layout>
</template>
<script>
import api from '@/services/api'
import utils from '@/services/utils'
export default {
  name: 'PlaceInfoForm',
  props: {
    locale: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    places: [],
    placeID: null,
  }),
  computed: {
    workspaceID () {
      return utils.getWorkspace('booking')
    },
  },
  watch: {
  },
  mounted () {
    this.placeID = this.value
    this.handleGetPlaces ()
  },
  methods: {
    handleGetPlaces () {
      api.getAllWithoutLimit ('booking', `v1/private/workspaces/${this.workspaceID}/places/`)
        .then(response => {
          this.places = response.data.map(item => {
            return {
              ID: item.ID,
              Name: utils.getTranslation(item.Address, this.locale)
            }
          })
        })
    },
    prepareData () {
      this.name = this.value.name
      if (!this.value || !this.value.coordinates) return
      const aux = this.value.coordinates.split(',')
      if (aux.length !== 2) return
      this.lat = aux[0]
      this.lon = aux[1]
    },
    handleChange () {
      this.$emit('input', this.placeID)
    },
  }
}
</script>
<style scoped>
.place-info-form {
  background-color: #CCCCCC;
  border-radius: 10px;
  padding: 10px;
}
.place-info-form p {
  margin: 0px;
}
</style>

