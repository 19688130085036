const constants = {
  userPermission: [
    { 
      id: '10816251-769b-4e52-8dc2-ade3b887fa26',
      name: 'dashboard',
      icon: 'mdi-view-dashboard', 
      mainPath: 'dashboard',
      path: '/dtouch/dashboard', 
    },
    {
      id: 'e7a45310-2028-4572-aa77-b8e37dc0cc1b',
      name: 'resources',
      icon: 'mdi-seat',
      mainPath: 'resources',
      path: '/booking/resources',
    },
    {
      id: 'fcfbe534-bbaf-48bd-bd7e-442ef1e2f113',
      name: 'places',
      icon: 'mdi-map-marker-radius',
      mainPath: 'places',
      path: '/booking/places',
    },
    {
      id: 'af7c5c22-3bbc-4b85-9437-b2e3adda51c3',
      name: 'activities',
      icon: 'mdi-ticket',
      mainPath: 'activities',
      path: '/book/activities',
    },
    {
      id: '898d6675-f41f-4aa2-9c4b-555f6f1c2bf1',
      name: 'calendar',
      icon: 'mdi-calendar-multiselect',
      mainPath: 'calendar',
      path: '/book/calendar',
    },
    {
      id: '66c22b8c-fc4e-414c-9d33-be7202a0fd13',
      name: 'relocateReservations',
      icon: 'mdi-calendar-question',
      mainPath: 'relocateReservations',
      path: '/booking/relocate-reservations',
    },
    {
      id: '1d16b79f-699b-4448-9471-415f24170dde',
      name: 'reservations',
      icon: 'mdi-bookmark',
      mainPath: 'reservations',
      path: '/book/reservations',
    },
    {
      id: '0b1ec849-0b55-48d7-ac91-e43a225602bd',
      name: 'attendance',
      icon: 'mdi-plus',
      mainPath: 'attendance',
      path: '/booking/attendance',
    },
    {
      id: '512c8864-52ca-445d-8c31-2a2e45132942',
      name: 'attendanceDelete',
      icon: 'mdi-delete',
      mainPath: 'attendance',
      path: '',
    },
    {
      id: 'a5c7ba9b-a5c1-4338-9235-c8a6c842882b',
      name: 'attendancePDF',
      icon: 'mdi-file-pdf-box',
      mainPath: 'attendance',
      path: '',
    },
    {
      id: 'ab76cd48-0eae-4398-8f9c-354ddab8936e',
      name: 'attendanceReservations',
      icon: 'mdi-file-sign',
      mainPath: 'attendanceReservations',
      path: '/booking/attendance-reservations',
    },
  ],
  defaultActivity: {
    ID: 'new',
    ScheduleType: 'specific',
    Name: { es: '' },
    Description: { es: '' },
    AllowReservations: false,
    Seats: 20,
    SeatsByBooking: 20,
    PlaceID: null,
    MeetingPointID: null,
    Place: {},
    MeetingPoint: {},
    Color: '#000000', 
    TimeLimitFromType: 'days',
    TimeLimitToType: 'days',
  },
  defaultFormFields: [
      {
        type: 'text',
        key: 'name',
        label: {
          es: 'Nombre',
          en: 'Name',
        },
        required: true,
      },
      {
        type: 'text',
        key: 'lastName',
        label: {
          es: 'Apellidos',
          en: 'Last name',
        },
        required: true,
      },
      {
        type: 'text',
        key: 'room',
        label: {
          es: 'Número de habitación',
          en: 'Room',
        },
        required: true,
      },
      {
        type: 'select',
        key: 'locale',
        label: {
          es: 'Idioma de contacto',
          en: 'Contact language',
        },
        items: [
          {
            id: 'es',
            name: {
              es: 'Español',
            },
          },
          {
            id: 'en',
            name: {
              es: 'Español',
            },
          },
          {
            id: 'de',
            name: {
              es: 'Alemán',
            },
          },
          {
            id: 'fr',
            name: {
              es: 'Francés',
            },
          },
          {
            id: 'it',
            name: {
              es: 'Italiano',
            },
          },
        ],
        defaultValue: 'es',
        required: true
      },
      {
        type: 'email',
        key: 'email',
        label: {
          es: 'Email',
          en: 'Email',
        },
        required: true
      },
      /*{
        id: 'ownerAllergens',
        type: 'multiselect',
        key: 'locale',
        label: {
          es: 'Alérgeno',
          en: 'Allergens',
        },
        items: [
          {
            id: 'es',
            name: {
              es: 'Español',
            },
          },
        ],
        defaultValue: null,
        required: false
      },*/
    ],
}

module.exports = constants

