<template>
  <div>
    <v-checkbox
      v-model="customPaxCount"
      label="Contadores personalizados"
      hide-details
      @change="handleChange"
    />
    <div v-if="customPaxCount" style="background-color: #CCCCCC80;margin-top:10px;padding: 0 0 15px 15px;">
      <v-layout wrap v-if="customPaxCount">
        <v-flex
          xs="6"
        >
          <v-checkbox
            v-model="children"
            label="Niños"
            hide-details
            @change="handleChange"
          />
        </v-flex>
        <v-flex
          xs="6"
        >
          <v-checkbox
            v-if="children"
            v-model="childrenAsCount"
            label="Afecta al total"
            hide-details
            @change="handleChange"
          />
        </v-flex>
      </v-layout>
      <v-layout wrap v-if="customPaxCount">
        <v-flex
          xs="6"
        >
          <v-checkbox
            v-model="baby"
            label="Bebés"
            hide-details
            @change="handleChange"
          />
        </v-flex>
        <v-flex
          xs="6"
        >
          <v-checkbox
            v-if="baby"
            v-model="babyAsCount"
            label="Afecta al total"
            hide-details
            @change="handleChange"
          />
        </v-flex>
      </v-layout>
      <v-layout wrap v-if="customPaxCount">
        <v-flex
          xs="6"
        >
          <v-checkbox
            v-model="adult"
            label="Adulto"
            hide-details
            @change="handleChange"
          />
        </v-flex>
        <v-flex
          xs="6"
        >
          <v-checkbox
            v-if="adult"
            v-model="adultAsCount"
            label="Afecta al total"
            hide-details
            @change="handleChange"
          />
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>
<script>
import api from '@/services/api'
export default {
  props: {
    workspaceID: {
      type: String,
      required: true,
    },
    onChange: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    customPaxCount: false,
    children: false,
    childrenAsCount: false,
    baby: false,
    babyAsCount: false,
    adult: false,
    adultAsCount: false,
  }),
  watch: {
    workspaceID () {
      this.handleGetData()
    },
  },
  mounted () {
    this.handleGetData()
  },
  methods: {
    handleGetData () {
      api.getAllWithoutLimit('booking', `v1/private/workspaces/${this.workspaceID}/custom-pax-count-config`)
        .then (response => {
          const aux = response.shift()
          if (aux) {
            this.customPaxCount = true
            this.children = aux.Children ? true : false
            this.childrenAsCount = aux.Children && aux.ChildrenAsCount ? true : false
            this.baby = aux.Baby ? true : false
            this.babyAsCount = aux.Baby && aux.BabyAsCount ? true : false
            this.adult = aux.Adult ? true : false
            this.adultAsCount = aux.Adult && aux.AdultAsCount ? true : false
          } else {
            this.customPaxCount = false
            this.children = false
            this.childrenAsCount = false
            this.baby = false
            this.babyAsCount = false
            this.adult = false
            this.adultAsCount = false
          }
        })
    },
    handleChange () {
      if (!this.customPaxCount) {
        this.onChange({
          customPaxCount: this.customPaxCount,
        })
        return 
      }

      const aux = {
        customPaxCount: this.customPaxCount,
        children: this.children,
        childrenAsCount: this.children ? this.childrenAsCount : false,
        baby: this.baby,
        babyAsCount: this.baby ? this.babyAsCount : false,
        adult: this.adult,
        adultAsCount: this.adult ? this.adultAsCount : false,
      }
      this.onChange(aux)
    },
  },
}
</script>

