<template>
  <v-dialog v-model="dialog" max-width="500px">      
    <v-card>
      <v-card-title class="headline">{{ $t('booking.activities.confirmUpdate', locale) }}</v-card-title>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="onUpdateEvents(false)">{{ $t('booking.activities.noUpdate', locale) }}</v-btn>
        <v-btn color="primary" dark @click="onUpdateEvents(true)">{{ $t('booking.activities.update', locale) }}</v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  export default {
    props: {
      showConfirmUpdateSeatsEvents: {
        type: Boolean,
        required: true,
      },
      id: {
        type: String,
        requied: true,
      },
      icon: {
        type: String,
        requied: false,
        default: null
      },
      onUpdateEvents: {
        type: Function,
        requied: true
      },
      locale: {
        type: String,
        requied: true
      },
      onlyIcon: {
        type: Boolean,
        default: false,
      },
      block: {
        type: Boolean,
        default: false,
      },
    },
    data: () => ({
      dialog: false
    }),
    watch: {
      showConfirmUpdateSeatsEvents (v) {
        this.dialog = v
      }
    },
  }
</script>

