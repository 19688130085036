<template>
    <v-layout wrap>
      <v-flex xs12>
        <v-list-item two-line>
          <v-list-item-content>
            <!--v-list-item-title class="text-h5 mb-1">
              {{ $t('booking.formFields.ownerData', locale) }}
            </v-list-item-title-->
            <v-list-item-subtitle>
              <v-checkbox
                v-model="paxAsCount"
                :label="$t('booking.formFields.paxCheckLabel', locale)"
                hide-details
                @change="handleChangeIsModified"
              />
              <p>
              <small style="margin-left: 35px;">{{$t('booking.formFields.paxCheckInfo', locale)}}</small>
              </p>
              <custom-pax-count-config
                v-if="isWorkspaceForm && paxAsCount"
                :workspaceID="workspaceID"
                :onChange="handleChangeCustomPaxCountConfig"
              />
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-flex>
      <v-flex xs12>
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title class="text-h5 mb-1">
              {{ $t('booking.formFields.ownerData', locale) }}
            </v-list-item-title>
            <v-list-item-subtitle>
              Seleccione los datos que se solicitarán al comprador de la reseva.
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-flex>
      <v-flex xs12
        v-if="ownerFormFields"
      >
      <v-layout wrap
        v-for="item of ownerFormFields"
        :key="item.key"
        style="padding: 0 10px"
      >
        <v-flex 
          xs7
        >
          <v-checkbox
            v-model="owner"
            :label="getTranslation(item.label, locale)"
            :value="item.key"
            hide-details
            @change="handleChangeIsModified"
          />
        </v-flex>
        <v-flex 
          xs5
        >
          <v-switch
            v-if="owner.indexOf(item.key) >= 0"
            v-model="item.required"
            :label="$t('booking.formFields.required', locale)"
            hide-details
            @change="handleChangeIsModified"
          />
        </v-flex>
        <v-flex xs12 v-if="item.id === 'ownerRoom' && owner.indexOf(item.key) >= 0" style="text-align: right;">
          <table style="width: 100%">
            <tr>
              <td>
              </td>
              <td style="width: 250px;">
          <v-switch
            v-if="owner.indexOf(item.key) >= 0"
            v-model="item.customRoom"
            :label="$t('booking.formFields.customRoom', locale)"
            hide-details
            @change="handleChangeIsModified"
                  dense
          />
              </td>
            </tr>
          </table>
        </v-flex>
      </v-layout>
      </v-flex>
      <template v-if="!paxAsCount">
      <v-flex xs12 style="margin-top: 20px">
        <v-divider />
      </v-flex>
      <v-flex xs12>
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title class="text-h5 mb-1">
              {{ $t('booking.formFields.paxData', locale) }}
            </v-list-item-title>
            <v-list-item-subtitle>
              Añada los datos que se solicitarán a los asistentes.
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-flex>
      <v-flex xs12>
        <form-fields-table 
          :formFields="pax"
          :locale="locale"
          :onEdit="handleEdit"
          :onDelete="handleDelete"
          :isOwner="false"
        />
      </v-flex>
      <v-flex xs12 style="text-align: right;margin-top: 20px;padding: 10px;">
        <form-field-item
          :editedFormField="editedPaxFormField"
          :onClose="handleCloseFormFieldItem"
          :locale="locale"
          :dialog="showFormFieldItem"
          :isAssisted="true"
        />
          <v-btn block :elevation="0" color="primary" @click="handleAddPaxData">
          {{ $t('booking.formFields.addPax', locale) }}
        </v-btn>
      </v-flex>
      </template>
    </v-layout>
</template>
<script>
import api from '@/services/api'
import utils from '@/services/utils'
import FormFieldItem from './FormFieldItem'
import FormFieldsTable from './FormFieldsTable'
import CustomPaxCountConfig from './CustomPaxCountConfig'
export default {
  components: {
    FormFieldItem,
    FormFieldsTable,
    CustomPaxCountConfig,
  },
  props: {
    locale: {
      type: String,
      required: true,
    },
    onChange: {
      type: Function,
      required: true,
    },
    formFields: {
      type: Object,
      default: null,
    },
    pac: {
      type: Boolean,
      defaut: false,
    },
    workspaceID: {
      type: String,
      default: null,
    },
    isWorkspaceForm: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    ownerFormFields: null,
    paxAsCount: false,
    owner: [],
    pax: [],
    editedPaxFormField: null,
    showFormFieldItem: false,
    customPaxCountConfig: null,
  }),
  mounted () {
    this.handleGetOwnerFormFields ()
  },
  methods: {
    getTranslation (v, locale) {
      return utils.getTranslation(v, locale)
    },
    handleAddPaxData () {
      this.editedPaxFormField = {
        id: utils.getNewID(),
        type: 'text',
        label: { es: '' },
        required: true,
      }
      this.showFormFieldItem = true
    },
    handleGetOwnerFormFields () {
      this.paxAsCount = this.pac
      api.getAllWithoutLimit('booking', 'v1/internal/constants/owner-form-fields')
        .then (response => {
          this.ownerFormFields = response.ownerFormFields.filter(x => !x.workspaceID || x.workspaceID.indexOf(this.workspaceID) >= 0).map(item => {
            const aux = this.formFields && this.formFields.owner ? this.formFields.owner.filter(x => x.key === item.key).shift() : null
            item.required = aux && aux.required ? true : false
            item.customRoom = aux && aux.customRoom ? true : false
            return item
          })
          this.initFormFields()
        })
    },
    initFormFields () {
      if (this.formFields) {
        if (this.formFields.owner) this.owner = this.formFields.owner.map(item => { return item.key })
        if (this.formFields.pax) this.pax = this.formFields.pax
      }
      this.handleChangeIsModified()
    },
    handleEdit (v) {
      this.editedPaxFormField = v
      this.showFormFieldItem = true
      /*
      this.isFormFieldItemOwner = isOwner
      v.LMD = new Date().toISOString()
      v.activityID = this.editedActivityID
      this.editedFormField = v
      */
    },
    handleDelete (v) {
      this.pax = this.pax.filter(x => x.id!==v)
      this.handleChangeIsModified()
      /*
      this.loading = true
      api.deleteItem ('booking', `v1/private/activities/${this.editedActivityID}/form-fields/`, v)
        .then(() => {
          this.loading = false
          this.handleGetData()
        })
*/
    },
    handleCloseFormFieldItem (v) {
      if(v) {
        this.pax = this.pax.filter(x => x.id !== v.id)
        this.pax.push(v)
      } 
      this.showFormFieldItem = false
      this.handleChangeIsModified()
    },
    prepareOwner (v) {
      if (!v) return []
      const result = []
      for (const item of v) {
        const aux = this.ownerFormFields.filter(x => x.key === item).shift()
        result.push(aux)
      }
      return result
    },
    handleChangeCustomPaxCountConfig (v) {
      this.customPaxCountConfig = v
      this.handleChangeIsModified()
    },
    handleChangeIsModified () {
      if (this.paxAsCount) this.pax = [{
        id: 'info',
        key: 'info',
        label: { es: 'info' },
        required: true,
        type: 'text',
      }]
      this.onChange({
        paxAsCount: this.paxAsCount,
        owner: this.prepareOwner(this.owner),
        pax: this.pax,
        customPaxCountConfig: this.customPaxCountConfig,
      })
    },
  },
}
</script>

