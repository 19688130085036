<template>
  <v-expansion-panel
    v-if="!isNew"
    @click="handleExpand"
  >
    <v-expansion-panel-header>{{ $t('booking.limitFieldsTime.title', locale)}}</v-expansion-panel-header>
    <v-expansion-panel-content
      class="form-fields-list-main"
    >
      <v-layout wrap>
        <v-flex xs12 style="padding: 40px 20px 10px 20px">
          <v-autocomplete
            v-if="formFields"
            v-model="selectedFields"
            :items="formFields"
            outlined
            chips
            itemText="name"
            itemValue="id"
            small-chips
            label="campos"
            multiple
            hide-details
            @change="handleChange"
          />
        </v-flex>
        <v-flex 
          v-if="selectedFields && selectedFields.length > 1"
          xs12 
          style="padding: 10px 20px 10px 20px"
        >
          <v-select
            :items="modes"
            v-model="selectedMode"
            itemText="name"
            itemValue="id"
            label="Modo"
            hide-details
            outlined
            @change="handleChange"
          />
        </v-flex>
        <v-flex xs6 style="padding: 10px 10px 20px 20px">
          <custom-text-field-number
            v-model="timeLimit"
            label="Tiempo"
            @input="handleChange"
          />
        </v-flex>
        <v-flex xs6 style="padding: 10px 20px 20px 10px">
          <v-select
            v-model="selectedTimeType"
            :items="[{
                id: 'days',
                name:  $t('booking.activities.days', locale),
              },
              {
                id: 'minutes',
                name:  $t('booking.activities.minutes', locale),
              },
            ]"
            itemText="name"
            itemValue="id"
            outlined
            hide-details
            @change="handleChange"
          />
        </v-flex>
      </v-layout>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>
import api from '@/services/api'
import utils from '@/services/utils'
import CustomTextFieldNumber from '@/components/CustomTextFieldNumber'
export default {
  components: {
    CustomTextFieldNumber,
  },
  props: {
    locale: {
      type: String,
      default: null,
    },
    editedActivityID: {
      type: String,
      default: null,
    },
    onChange: {
      type: Function,
      required: true,
    },
    allowReservations: {
      type: Boolean,
      default: false,
    },
    workspaceID: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    formFields: null,
    selectedFields: null,
    modes: [
      {
        id: 'and',
        name: 'and',
      },
      {
        id: 'or',
        name: 'or'
      }
    ],
    selectedMode: 'and',
    timeLimit: 0,
    selectedTimeType: 'days'
  }),
  computed: {
    isNew () {
      return this.editedActivityID === 'new'
    },
  },
  methods: {
    handleExpand () {
      if (this.editedActivityID) this.handleGetData()
    },
    handleGetData () {
      api.getAllWithoutLimit ('booking', `v1/private/activities/${this.editedActivityID}/limit-fields-time`)
        .then(response => {
          let aux = response.shift()
          if (aux) {
            if (aux.Data && aux.Data.length > 0) aux = JSON.parse(aux.Data)
            if (aux.fields) this.selectedFields = aux.fields
            if (aux.mode) this.selectedMode = aux.mode
            if (aux.timeLimit) this.timeLimit = aux.timeLimit
            if (aux.timeType) this.selectedTimeType = aux.timeType
          }
          this.handleGetFields()
        })
    },
    handleGetFields () {
      this.loading = true
      api.getAllWithoutLimit ('booking', `v1/private/activities/${this.editedActivityID}/form-fields`)
        .then(response => {
          if (!response || response.lenfth === 0) return
          const aux = JSON.parse(response.shift().FormFields)
          console.log(aux)
          if (aux) this.formFields = aux.owner.filter(x => x.limited).map(item => {
            return {
              id: item.key,
              name: utils.getTranslation(item.label, this.locale)
            }
          })
        })
    },
    handleChange () {
      this.onChange({
        fields: this.selectedFields,
        mode: this.selectedMode,
        timeLimit: this.timeLimit,
        timeType: this.selectedTimeType,
      })
    },
  },
}
</script>

