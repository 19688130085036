<template>
  <v-layout wrap v-if="values" style="margin-top: 20px;border-top: 1px solid #CCCCCC80;border-bottom: 1px solid #CCCCCC80;">
    <v-flex xs12
      style="margin-top:10px;padding: 10px 0px 0px 20px;"
    >
      <small>{{ $t('booking.customFieldsInfo.title', locale) }} :</small>
    </v-flex>
    <v-flex xs11
      style="padding: 0px 20px 20px 20px"
      v-for="(item, index) of fields"
      :key="index"
    >
      <translate
        v-if="item.Type === 'text'"
        v-model="values[item.ID]"
        :languages="languages"
        :label="getTranslation(item.Name, locale)"
        @input="handleChangeIsModified"
        type="text"
      />

      <v-layout wrap v-else-if="item.Type === 'link'">
        <v-flex xs12>
          <small>{{getTranslation(item.Name, locale)}} :</small>
        </v-flex>
        <v-flex xs6 style="padding: 10px 10px 0 0px;">
            <translate
              v-model="values[item.ID].text"
              :languages="languages"
              label="Text"
              @input="handleChangeIsModified"
              type="text"
            />
        </v-flex>
        <v-flex xs6 style="padding: 10px 0 10px 20px;">
            <translate
              v-model="values[item.ID].link"
              :languages="languages"
              label="Link"
              @input="handleChangeIsModified"
              type="text"
            />
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>
<script>
import axios from 'axios'
import api from '@/services/api'
import utils from '@/services/utils'
import Translate from '@/components/Translate'
export default {
  components: {
    Translate,
  },
  props: {
    workspaceID: {
      type: String,
      required: true,
    },
    activityID: {
      type: String,
      default: null
    },
    languages: {
      type: Array,
      required: true,
    },
    locale: {
      type: String,
      required: true,
    },
    onChange: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    fields: null,
    values: null,
  }),
  watch: {
    activityID () {
      this.handleGetData()
    },
  },
  mounted () {
    this.preapareData()
  },
  methods: {
    getTranslation (v, locale) {
      return utils.getTranslation(v, locale)
    },
    preapareData () {
      this.handleGetData()
    },
    handleGetData () {
      api.getAllWithoutLimit('booking', `v1/system/workspaces/${this.workspaceID}/custom-fields-info`)
        .then(response => {
          this.fields = response.map(item => {
            item.Name = JSON.parse(item.Name)
            return item
          })
          this.handleGetValues ()
        })
    },
    handleGetValues () {
       const url = `${window.location.protocol}//${process.env.VUE_APP_API_URL_BOOKING}/v1/public/activities/${this.activityID}/custom-fields-info-data`
       axios.get(url)
          .then((response) => {
            if (!response || !response.data || !response.data.values) this.prepareDefaultValues()
            else this.values = response.data.values
            /*
             this.info = []
             //this.info = response.data
             for (const item of response.data.fields) {
                const aux = {
                   type: item.Type
                }
                if (item.Type === 'link') {
                   const v = response.data.values[item.ID]
                   aux.label = v.text ? utils.getTranslation(v.text, this.locale) : null
                   aux.value = v.link ? utils.getTranslation(v.link, this.locale) : null

                } else {
                   aux.label = item.Name ? utils.getTranslation(item.Name, this.locale) : null
                   const v = response.data.values[item.ID]
                   aux.value = v ? utils.getTranslation(v, this.locale) : null
                }

                if (aux.type && aux.label && aux.label.length > 0 && aux.value && aux.value.length > 0) this.info.push(aux)
             }*/
          })
          .catch(error => {
             console.log('jjh', error)
          })
    },
    prepareDefaultValues () {
      const result = {}
      for (const item of this.fields) {
        if (item.Type === 'link') {
          result[item.ID] = {
            text: { es: '' },
            link: { es: '' }
          }
        } else {
          result[item.ID] = { es: '' }
        }
      }

      this.values = result
    },
    handleChangeIsModified () {
      this.onChange(this.values)
    },
  }, 
}
</script>

