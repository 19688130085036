<template>
  <v-expansion-panel
    v-if="!isNew"
    @click="handleExpand"
  >
    <v-expansion-panel-header>{{ $t('booking.terms.title', locale)}}</v-expansion-panel-header>
    <v-expansion-panel-content
    >
      <v-checkbox
        v-model="requiredTermsActive"
        :label="$t('booking.terms.requiredTermsActive')"
        hide-details
        @change="handleChangeIsModified"
      />

      <translate
        style="margin-top: 10px;"
        v-model="requiredTerms"
        :languages="languages"
        :label="$t('booking.terms.requiredTerms', locale)"
        @input="handleChangeIsModified"
        type="textArea"
      />
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>
import Translate from '@/components/Translate'
import api from '@/services/api'
//import utils from '@/services/utils'
export default {
  components: {
    Translate,
  },
  props: {
    locale: {
      type: String,
      default: null,
    },
    languages: {
      type: Array,
      required: true,
    },
    editedActivityID: {
      type: String,
      default: null,
    },
    onChange: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    requiredTermsActive: null,
    requiredTerms: null,
  }),
  computed: {
    isNew () {
      return this.editedActivityID === 'new'
    },
  },
  methods: {
    handleExpand () {
      if (this.editedActivityID && this.requiredTermsActive === null) this.handleGetData()
    },
    handleGetData () {
      this.loading = true
      api.getItem('booking', `v1/private/activities/`, `${this.editedActivityID}/terms`)
        .then(response => {
          if (!response) {
            this.requiredTermsActive = false
            this.requiredTerms = {
              es: ''
            }
          } else {
            console.log(response)
            this.requiredTermsActive = response.RequiredTermsActive
            this.requiredTerms = JSON.parse(response.RequiredTerms)
          }
          this.loading = false
       })
    },
    handleChangeIsModified () {
      this.onChange({
        RequiredTermsActive: this.requiredTermsActive,
        RequiredTerms: this.requiredTerms,
      })
    },
  },
}
</script>

