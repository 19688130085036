<template>
  <v-layout wrap v-if="editedItem" class="form-menu">
    <v-toolbar
      elevation="0"     
    >
      <v-icon large style="margin-right:10px;">mdi-ticket</v-icon>
      <v-toolbar-title>
        {{ $t(isNew ? 'common.create' : 'common.edit', locale) }} {{ $t('booking.activity', locale).toLowerCase() }} {{ !isNew ? `(${getTranslation(editedItem.Name, locale)})` : ''}}
      </v-toolbar-title>
    </v-toolbar>
    <v-flex xs12>
      <v-expansion-panels focusable v-model="panel" multiple>
        <v-expansion-panel :key="0">
          <v-expansion-panel-header>{{ $t('booking.details', locale)}}</v-expansion-panel-header>
          <v-expansion-panel-content
            class="schedules-list-main"
          >
    <v-layout wrap>
    <v-flex xs12 style="padding:20px 20px 0 20px">
      <translate
        v-model="editedItem.Name"
        :languages="languages"
        :label="$t('booking.name', locale)"
        @input="handleChangeIsModified"
        type="text"
      />
    </v-flex>
    <v-flex xs12 style="padding:20px 20px 0 20px">
      <translate
        v-model="editedItem.Subtitle"
        :languages="languages"
        :label="$t('booking.subtitle', locale)"
        @input="handleChangeIsModified"
        type="text"
      />
    </v-flex>

    <v-flex xs12 style="padding:20px 20px 0 20px">
      <translate
        v-model="editedItem.Description"
        :languages="languages"
        :label="$t('booking.description', locale)"
        @input="handleChangeIsModified"
        type="richText"
      />
    </v-flex>
    <v-flex xs12>
      <custom-fields-info
        :workspaceID="workspaceID"
        :activityID="editedItem.ID"
        :locale="locale"
        :languages="languages"
        :onChange="handleChangeCustomFieldsInfo"
      />
    </v-flex>
    <v-flex v-if="categories" xs12 style="padding:20px 10px 0 10px;">
      <v-autocomplete
        v-model="editedItem.CategoryID"
        :items="categories"
        outlined
        itemText="Name"
        itemValue="ID"
        hide-details
        :label="$t('booking.category', locale)"
        @change="handleChangeIsModified"
      />
    </v-flex>
    <v-flex xs12 style="padding:20px 10px 0 10px">
      <color-picker-item
        :value="editedItem.Color"
        :label="$t('common.color', locale)"
        :onChange="handleChangeColor"
      />
    </v-flex>
    <v-flex xs12 style="padding:20px 20px 0 20px">
      <place-list-form v-model="editedItem.PlaceID" :locale="locale" @input="handleChangeIsModified" :label="$t('booking.place', locale)" />
      <!--place-info-form v-model="editedItem.Place" :locale="locale" @input="handleChangeIsModified" :label="$t('booking.place', locale)" /-->
    </v-flex>
    <v-flex xs12 style="padding:20px 20px 0 20px">
      <place-list-form v-model="editedItem.MeetingPointID" :locale="locale" @input="handleChangeIsModified" :label="$t('booking.meetingPoint', locale)" />
      <!--place-info-form v-model="editedItem.MeetingPoint" :locale="locale" @input="handleChangeIsModified" :label="$t('booking.meetingPoint', locale)" /-->
    </v-flex>
    <v-flex xs12
      style="margin-top:20px;padding: 10px 20px 0px 20px;border-top: 1px solid #CCCCCC80;"
    >
      <small>{{ $t('booking.forceOrder', locale) }} :</small>
    </v-flex>
    <v-flex xs6
      style="padding: 0px 20px 20px 20px"
    >
      <v-checkbox
        v-model="editedItem.ForceFirst"
        :label="$t('booking.forceFirst', locale)"
        @click="editedItem.ForceLast = editedItem.ForceFirst ? false : editedItem.ForceLast"
        hide-details
      />
    </v-flex>
    <v-flex xs6
      style="padding: 0px 20px 20px 20px"
    >
      <v-checkbox
        v-model="editedItem.ForceLast"
        :label="$t('booking.forceLast', locale)"
        @click="editedItem.ForceFirst = editedItem.ForceLast ? false : editedItem.ForceFirst"
        hide-details
      />
    </v-flex>
    <v-flex xs6
      v-if="editedItem.WorkspaceAllowReservations"
      style="padding: 10px 20px 10px 20px;border-top: 1px solid #CCCCCC80 !important;"
    >
      <v-checkbox
        v-model="editedItem.AllowReservations"
        :label="$t('booking.allowReservations', locale)"
        hide-details
      />
    </v-flex>
    <v-flex xs6
      v-if="editedItem.WorkspaceAllowReservations && editedItem.AllowReservations"
      style="padding: 10px 20px 10px 20px;border-top: 1px solid #CCCCCC80 !important;"
    >
      <v-checkbox
        v-model="editedItem.AllowReservationsEventStart"
        :label="$t('booking.allowReservationsEventStart', locale)"
        hide-details
      />
    </v-flex>
    <template
      v-if="editedItem.AllowReservations"
    >
      <template style="padding: 0 20px">

            <v-flex xs12 style="padding: 20px">
              <h3>
                {{ $t('booking.activities.timeLimit', locale) }}
              </h3>
            </v-flex>
            <v-flex xs6 style="padding: 0 10px 0 20px">
              <custom-text-field-number
                v-model="editedItem.TimeLimitFrom"
                :label="$t('booking.activities.timeLimitFrom', locale)"
              />
            </v-flex>
            <v-flex xs6 style="padding: 0 20px 0 10px">
              <v-select
                v-model="editedItem.TimeLimitFromType"
                :items="[{
                    id: 'days',
                    name:  $t('booking.activities.days', locale),
                  },
                  {
                    id: 'minutes',
                    name:  $t('booking.activities.minutes', locale),
                  },
                ]"
                itemText="name"
                itemValue="id"
                outlined
                hide-details
              />
            </v-flex>
            <v-flex xs6 style="padding: 10px 10px 0 20px">
              <custom-text-field-number
                v-model="editedItem.TimeLimitTo"
                :label="$t('booking.activities.timeLimitTo', locale)"
              />
            </v-flex>
            <v-flex xs6 style="padding: 10px 20px 0 10px">
              <v-select
                v-model="editedItem.TimeLimitToType"
                :items="[{
                    id: 'days',
                    name:  $t('booking.activities.days', locale),
                  },
                  {
                    id: 'minutes',
                    name:  $t('booking.activities.minutes', locale),
                  },
                ]"
                itemText="name"
                itemValue="id"
                outlined
                hide-details
              />
            </v-flex>
          </template>

    
            <v-flex xs12 style="padding: 20px 20px 0 20px">
              <h3>
                {{ $t('booking.activities.quotes', locale) }}
              </h3>
            </v-flex>
    <v-flex xs12
      style="padding: 0 0 10px 0"
    >
      <seats-limit
        :editedItem="editedItem"
        :locale="locale"
        :onChange="handleChangeSeatsLimit"
      />
      <!--custom-text-field-number
        v-model="editedItem.BookingLimit"
        :label="$t('booking.activities.bookingLimit', locale)"
      />
      <custom-text-field-number
        style="margin-top:20px"
        v-model="editedItem.Seats"
        :label="$t('booking.activities.seats', locale)"
      />
      <custom-text-field-number
        style="margin-top:20px"
        v-model="editedItem.SeatsByBooking"
        :label="$t('booking.activities.seatsByBooking', locale)"
      /-->
    </v-flex>
      <v-flex 
        v-if="editedItem.SeatsType!=='byResources'"
        xs12 style="padding: 0 20px 20px 20px"
      >
        <h3>
          {{ $t('booking.activities.maxRageEventReservationTitle', locale) }}
        </h3>
      </v-flex>
      <v-flex 
        v-if="editedItem.SeatsType!=='byResources'"
        xs12 style="padding: 0 10px 20px 10px"
      >
        <custom-text-field-number
          v-model="editedItem.MaxRageEventReservation"
          :label="$t('booking.activities.maxRageEventReservation', locale)"
        />
      </v-flex>
    </template>
    <v-flex xs12 
      class="common-forms"
      style="padding: 20px"
    >
      <select-image 
        :image="image"
        :onChange="handleChangeImage"
        :locale="locale"
        :imageWidth="800"
        :imageHeight="600"
        :aspectRatio="16/9"
        :folder="workspaceID"
        :limit="1024"
      />
    </v-flex>
    </v-layout>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel
        >
          <v-expansion-panel-header>{{ $t('booking.notificationForm.title', locale)}}</v-expansion-panel-header>
          <v-expansion-panel-content
          >
            <translate
              style="margin-top: 10px;"
              v-model="editedItem.NotificationExtraInfo"
              :languages="languages"
              :label="$t('booking.notificationForm.notificationExtraInfo', locale)"
              @input="handleChangeIsModified"
              type="textArea"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
        <terms
          v-if="editedItem.AllowReservations"
          :locale="locale"
          :languages="languages"
          :editedActivityID="editedID"
          :onChange="handleChangeTerms"
        />
        <form-fields
          :locale="locale"
          :editedActivityID="editedID"
          :onChange="handleChangeFormFields"
          :allowReservations="editedItem.AllowReservations"
          :workspaceID="workspaceID"
        />
        <limit-fields-time
          :locale="locale"
          :editedActivityID="editedID"
          :onChange="handleChangeLimitFieldsTime"
          :allowReservations="editedItem.AllowReservations"
          :workspaceID="workspaceID"
        />
        <schedules
          :locale="locale"
          :editedScheduleType="editedItem.ScheduleType"
          :editedActivityID="editedID"
          :activitySeats="editedItem.Seats"
        />

      </v-expansion-panels>
    </v-flex>
    <v-flex xs12>
      <action-buttons
        :id="editedItem.ID"
        :onDeleteItem="handleDelete"
        :onSaveItem="handleCheckSeatsConfigUpdated"
        :onClose="onClose"
        :locale="locale"
      />
      <confirm-update-seats-events 
        :showConfirmUpdateSeatsEvents="showConfirmUpdateSeatsEvents"
        :onUpdateEvents="handleUpdateSeatsEvents"
        :locale="locale"
      />
      <div style="text-align:right;opacity:20%;">
        <small >{{ editedItem.ID }}</small>
      </div>
    </v-flex>
  </v-layout>
</template>
<script>
import { mapState } from 'vuex'
import api from '@/services/api'
import utils from '@/services/utils'
import { defaultActivity } from '@/constants/booking'
import SeatsLimit from '@/components/seatsLimit/Index'
import SelectImage from '@/components/selectImage/Index'
import Translate from '@/components/Translate'
import ActionButtons from '@/components/ActionButtons'
import ColorPickerItem from '@/components/common-components/ColorPickerItem'
import CustomTextFieldNumber from '@/components/CustomTextFieldNumber'
import Schedules from './Schedules'
import FormFields from './formFields/Index'
import Terms from './Terms'
import ConfirmUpdateSeatsEvents from './ConfirmUpdateSeatsEvents'
//import PlaceInfoForm from './PlaceInfoForm'
import PlaceListForm from './PlaceListForm'
import LimitFieldsTime from './limitFieldsTime/Index'
import CustomFieldsInfo from './CustomFieldsInfo'
export default {
  name: 'folder-form',
  components: {
    Translate,
    ActionButtons,
    SeatsLimit,
    Schedules,
    FormFields,
    SelectImage,
    //PlaceInfoForm,
    PlaceListForm,
    ColorPickerItem,
    CustomTextFieldNumber,
    Terms,
    ConfirmUpdateSeatsEvents,
    LimitFieldsTime,
    CustomFieldsInfo,
  },
  props: {
    editedID: {
      type: String,
      default: null,
    },
    onClose: {
      type: Function,
      required: true
    },
    newParentID: {
      // used only when creating a new menu
      type: String,
      default: null,
    },
  },
  data () {
    return {
      panel: [],
      image: null,
      editedItem: null,
      showSelectCustomer: false,
      categories: null,

      seatsConfigOld: null,
      showConfirmUpdateSeatsEvents: false,
    }
  },
  computed: {        
    ...mapState('app', ['locale']),
    languages () {
      return this.$store.state.app.bookingLanguages
    },
    workspaceID () {
      return utils.getWorkspace('booking')
    },
    isNew () {
      return this.editedID === 'new'
    },
  },
  watch: {
    editedID (v) {
      this.panel = this.isNew ? [0] : []
      if (v) this.handleGetItem()
    },
    'editedItem.AllowReservations' (v) {
      if (!v) {
        this.editedItem.Seats = null
        this.editedItem.SeatsByBookings = null
      }
    },
  },
  mounted () {
    this.handleGetCategories()
    this.panel = this.isNew ? [0] : []
    if (this.editedID) this.handleGetItem()
  },
  methods: {
    getTranslation(v, locale) {
      return utils.getTranslation(v, locale)
    },
    handleGetCategories () {
      api.getAllWithoutLimit('booking', `v1/system/workspaces/${this.workspaceID}/categories`)
        .then(response => {
          this.categories = response ? response.map(item => {
            item.Name = utils.getTranslation(item.Name, this.locale)
            return item
          }) : []
        })
    },
    handleGetItem () {
      if (this.isNew) {
        this.editedItem = JSON.parse(JSON.stringify(defaultActivity))

        // default color = enterpriseColor
        this.editedItem.Color = this.$vuetify.theme.themes.light.primary

        this.editedItem.WorkspaceID = this.workspaceID
      } else 
        api.getItem ('booking', 'v1/private/activities/', this.editedID)
          .then(response => {
            response.Name = JSON.parse(response.Name)
            response.Subtitle = JSON.parse(response.Subtitle)
            response.Description = JSON.parse(response.Description)
            response.Place = JSON.parse(response.Place)
            response.MeetingPoint = JSON.parse(response.MeetingPoint)
            response.NotificationExtraInfo = response.NotificationExtraInfo ? JSON.parse(response.NotificationExtraInfo) : { es: '' }
            response.AllowReservations = response.AllowReservations === 1 || response.AllowReservations === true ? true : false
            this.image = response.Image
            this.editedItem = response
            if (response.AllowReservations) {
              this.seatsConfigOld = {
                BookingLimit: response.BookingLimit,
                Seats: response.Seats,
                SeatsByBooking: response.SeatsByBooking,
              }
            }
          })
    },
    handleChangeSeatsLimit (v) {
      this.editedItem.SeatsType = v.SeatsType
      this.editedItem.Seats = v.Seats
      this.editedItem.SeatsByBooking = v.SeatsByBooking
      this.editedItem.BookingLimit = v.BookingLimit
      this.editedItem.ResourceID = v.ResourceID
    },
    handleChangeIsModified () {
      this.$store.state.app.dtouchMenuEdit = {
        ...this.editedItem,
        LMD: new Date().getTime()
      }
    },
    handleChangeImage (v) {
      if (v) {
        //if (!utils.checkIsBase64(this.content.FoodImage)) v.urlImageToDelete = this.content.FoodImage
        this.image = v.base64
        this.editedItem.newImage = v //'https://social.infoten.es/Fichas/PublishingImages/capture_03112013_152110.jpg'
        this.handleChangeIsModified()
      }
    },
    handleChangeTerms (v) {
      this.editedItem.Terms = v
      this.handleChangeIsModified ()
    },
    handleChangeFormFields (v) {
      //this.editedItem.FormFields = v
      this.editedItem.FormFields = {}
      this.editedItem.FormFields.activityID = this.editedID
      this.editedItem.FormFields.formFields = v
      this.handleChangeIsModified()
    },
    handleChangeLimitFieldsTime (v) {
      this.editedItem.LimitFieldsTime = v
      this.handleChangeIsModified()
    },
    handleChangeColorMenu (bg, text) {
      this.editedItem.BGColor = bg
      this.editedItem.TextColor = text
      this.handleChangeIsModified()
    },
    handleChangeColorContent (bg, text) {
      this.editedItem.BGColorContent = bg
      this.editedItem.TextColorContent = text
      this.handleChangeIsModified()
    },
    handleChangeVisibility (v) {
      this.editedItem.Visibility = v
      this.showSelectCustomer = this.editedItem.Visibility === 'privateByUser'
    },
    handleChangeColor (v) {
      this.editedItem.Color = v
    },
    handleCheckSeatsConfigUpdated () {
      if (this.seatsConfigOld && (this.seatsConfigOld.BookingLimit !== this.editedItem.BookingLimit
          || this.seatsConfigOld.Seats !== this.editedItem.Seats
          || this.seatsConfigOld.SeatsByBooking !== this.editedItem.SeatsByBooking)) {
        this.showConfirmUpdateSeatsEvents = true
      } else this.handleSave()
    },
    handleUpdateSeatsEvents (v) {
      this.editedItem.UpdateSeatsEvents = v
      this.showConfirmUpdateSeatsEvents = false
      this.handleSave()
    },
    handleChangeCustomFieldsInfo (v) {
      this.editedItem.CustomFieldsInfo = v
    },
    handleSave () {
      this.editedItem.MaxRageEventReservation = this.editedItem.MaxRageEventReservation ? this.editedItem.MaxRageEventReservation : null
      if (this.isNew)
        api.addItem ('booking', 'v1/private/activities/', this.editedItem)
          .then((response) => {
            this.onClose('add', response.data)
          })
      else 
        api.updateItem ('booking', 'v1/private/activities/', this.editedItem.ID, this.editedItem)
          .then((response) => {
            const id = response.data && response.data.ID ? response.data.ID : null
            this.onClose('update', id)
          })
    },
    handleDelete () {
      api.deleteItem('booking', `v1/private/activities/`, this.editedID)
        .then(response => {
          if(response && response.data.affectedRows > 0) this.onClose('delete', this.editedID)
        })
    },
  }
}
</script>
<style scoped>
.form-menu .availability {
  background-color: #f3f0f0;
  border-radius: 5px;
  padding: 0 20px 20px 20px;
  margin-top: 15px;
}
.form-menu .common-forms {
  padding: 0 10px 10px 10px;
}
</style>


